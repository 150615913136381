import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"

const SommierZonageSectionWrapper = styled.div`
  display: flex;
  background: #1b2a56;
  width: 100%;
`

const SommierZonageSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  margin: 0px auto;
  padding: 20px 20px;
  color: #fff;
`

const SectionTitle = styled.h2`
  font-size: 36px;
  font-weight: bold;
  font-family: "BebasNeueBold";
  line-height: 1;
  margin: 0px 0px 10px 0px;
  text-align: center;

  @media (max-width: 600px) {
    text-align: left;
  }
`

const SectionSubTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-family: "GothamLight";
  line-height: 1.2;
  margin: 0px 0px 10px 0px;
  text-align: center;

  @media (max-width: 600px) {
    display: none;
  }
`

const SectionMobileSubTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-family: "GothamLight";
  line-height: 1.2;
  margin: 0px 0px 10px 0px;
  text-align: left;
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`

const SommierZonageSection = ({ intl, data, id }) => {
  return (
    data && (
      <SommierZonageSectionWrapper
        id={id}
        className={id ? "lyneup-page-section" : ""}
      >
        <SommierZonageSectionContainer>
          {data.section_title && (
            <SectionTitle
              dangerouslySetInnerHTML={{
                __html: data.section_title,
              }}
            />
          )}
          {data.text && (
            <SectionSubTitle
              dangerouslySetInnerHTML={{
                __html: data.text,
              }}
            />
          )}
          {data.mobile_text && (
            <SectionMobileSubTitle
              dangerouslySetInnerHTML={{
                __html: data.mobile_text,
              }}
            />
          )}
        </SommierZonageSectionContainer>
      </SommierZonageSectionWrapper>
    )
  )
}

export default injectIntl(SommierZonageSection)
